import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

interface StripeCheckoutButtonProps {
  tier: 'paid' | 'premium';
  disabled?: boolean;
}

const StripeCheckoutButton: React.FC<StripeCheckoutButtonProps> = ({ tier, disabled = false }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleCheckout = async () => {
    if (disabled) return;
  
    try {
      setLoading(true);
      const stripe = await stripePromise;
      if (!stripe) throw new Error('Stripe failed to initialize');
  
      const response = await axios.post('/api/stripe/checkout', {
        subscriptionTier: tier
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
        }
      });
  
      if (response.status !== 200) {
        throw new Error('Failed to create checkout session');
      }
  
      const { sessionId } = response.data;
      const { error: stripeError } = await stripe.redirectToCheckout({ sessionId });
  
      if (stripeError) throw stripeError;
      console.log('Session ID:', sessionId);
    } catch (err: any) {
      console.error('Checkout Error:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <button
        onClick={handleCheckout}
        disabled={loading || disabled}
        className={`w-full p-2 bg-primary-light dark:bg-primary-dark text-white rounded hover:bg-primary-light dark:hover:bg-primary-dark transition duration-300 ${
          (loading || disabled) && 'opacity-50 cursor-not-allowed'
        }`}
      >
        {loading ? 'Processing...' : `Upgrade to ${tier === 'paid' ? 'Paid' : 'Premium'}`}
      </button>
      {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
    </div>
  );
};

export default StripeCheckoutButton;
