import React, { useState } from 'react';
import { supabase } from '../supabase';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Button } from '../components/ui/Button';
import { Gamepad2 } from 'lucide-react';

const UpdatePasswordPage: React.FC = () => {
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleUpdatePassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');
    setMessage('');
    const accessToken = searchParams.get('access_token');
    try {
      const { error } = await supabase.auth.updateUser({
        password,
      });
      if (error) throw error;
      setMessage('Password updated successfully!');
      setTimeout(() => navigate('/login'), 2000);
    } catch (err: any) {
      setMessage('Failed to update password: ' + err.message);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-light dark:bg-gradient-dark">
      <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md">
        <div className="text-center mb-6">
          <Gamepad2 className="mx-auto h-12 w-12 text-primary-light dark:text-primary-dark" />
          <h2 className="text-2xl font-bold text-secondary-light dark:text-secondary-dark">Set New Password</h2>
        </div>
        {message && <p className={`text-center mb-4 ${message.includes('successfully') ? 'text-green-500' : 'text-red-500'}`}>{message}</p>}
        <form onSubmit={handleUpdatePassword} className="space-y-4">
          <div>
            <label className="block text-sm font-medium mb-1 text-secondary-light dark:text-secondary-dark">New Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded bg-white dark:bg-gray-700 text-gray-800 dark:text-gray-200 focus:outline-none focus:ring-2 focus:ring-primary-light dark:focus:ring-primary-dark"
              placeholder="••••••••"
            />
          </div>
          <Button type="submit" className="w-full bg-primary-light dark:bg-primary-dark hover:bg-primary-light dark:hover:bg-primary-dark text-white">
            Update Password
          </Button>
        </form>
      </div>
    </div>
  );
};

export default UpdatePasswordPage;
