import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { supabase } from '../supabase';
import { Button } from '../components/ui/Button';
import { Gamepad2 } from 'lucide-react';

const VerifyEmailPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const email = location.state?.email;

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const params = new URLSearchParams(hash.substring(1));
      const type = params.get('type');
      const token = params.get('access_token');

      if (type === 'email_confirmation' && token) {
        handleEmailConfirmation(token);
      }
    }
  }, []);

  const handleEmailConfirmation = async (token: string) => {
    try {
      const { error } = await supabase.auth.verifyOtp({
        token_hash: token,
        type: 'email',
      });

      if (error) throw error;
      
      setMessage('Email verified successfully! You can now log in.');
      setTimeout(() => navigate('/login'), 3000);
    } catch (err: any) {
      setMessage('Failed to verify email: ' + err.message);
    }
  };

  const resendVerificationEmail = async () => {
    if (email) {
      try {
        const { error } = await supabase.auth.resend({
          type: 'signup',
          email: email,
        });
        
        if (error) throw error;
        setMessage('Verification email resent successfully! Please check your inbox.');
      } catch (err: any) {
        setMessage('Error resending verification email: ' + err.message);
      }
    } else {
      setMessage('Error: Email not found. Please try signing up again.');
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-light dark:bg-gradient-dark">
      <div className="max-w-md w-full space-y-8 p-8 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
        <div className="text-center">
          <Gamepad2 className="mx-auto h-12 w-12 text-primary-light dark:text-primary-dark" />
          <h2 className="mt-6 text-3xl font-extrabold text-secondary-light dark:text-secondary-dark">Verify your email</h2>
          <p className="mt-2 text-sm text-gray-700 dark:text-gray-300">
            We've sent a verification link to{' '}
            <span className="font-semibold">{email}</span>. Please click the link to verify your account.
          </p>
          {message && (
            <p className={`mt-2 text-sm ${message.includes('Error') ? 'text-red-500' : 'text-green-500'}`}>
              {message}
            </p>
          )}
        </div>
        <div className="mt-8 space-y-6">
          <Button 
            className="w-full bg-primary-light dark:bg-primary-dark hover:bg-primary-light dark:hover:bg-primary-dark text-white" 
            onClick={resendVerificationEmail}
          >
            Resend verification email
          </Button>
          <div className="text-center">
            <p className="mt-2 text-sm text-gray-700 dark:text-gray-300">
              Already verified?{' '}
              <Link to="/login" className="font-medium text-primary-light dark:text-primary-dark hover:text-primary-dark">
                Log in
              </Link>
            </p>
            <p className="mt-2 text-sm text-gray-700 dark:text-gray-300">
              Need help?{' '}
              <Link to="/contact" className="font-medium text-primary-light dark:text-primary-dark hover:text-primary-dark">
                Contact support
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailPage;
