import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { Button } from '../components/ui/Button';
import { Input } from '../components/ui/Input';
import axios from 'axios';

const AccountPage: React.FC = () => {
  const { accessToken, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [is2FAEnabled, setIs2FAEnabled] = useState<boolean>(false);

  const handleChangePassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setMessage('');
  
    if (newPassword !== confirmNewPassword) {
      setError('New passwords do not match.');
      return;
    }
  
    try {
      const { data } = await axios.post('/api/user/change-password', {
        currentPassword,
        newPassword,
      }, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
  
      setMessage('Password updated successfully.');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
    } catch (err: any) {
      console.error('Change Password Error:', err.response?.data?.message || err.message);
      setError(err.response?.data?.message || 'Failed to change password.');
    }
  };

  const toggle2FA = async () => {
    try {
      setIs2FAEnabled(!is2FAEnabled);
      setMessage(`2FA ${!is2FAEnabled ? 'enabled' : 'disabled'} successfully.`);
    } catch (err: any) {
      console.error('Toggle 2FA Error:', err);
      setError('Failed to toggle 2FA.');
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <div className="container mx-auto py-8 bg-background-light dark:bg-background-dark text-gray-800 dark:text-gray-200">
      <h1 className="text-3xl font-bold mb-8 text-secondary-light dark:text-secondary-dark">Account Settings</h1>

      {/* Change Password Section */}
      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-4 text-secondary-light dark:text-secondary-dark">Change Password</h2>
        <form onSubmit={handleChangePassword} className="space-y-4">
          <Input
            type="password"
            placeholder="Current Password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            required
            className="bg-white dark:bg-gray-700"
          />
          <Input
            type="password"
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
            className="bg-white dark:bg-gray-700"
          />
          <Input
            type="password"
            placeholder="Confirm New Password"
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            required
            className="bg-white dark:bg-gray-700"
          />
          {error && <p className="text-red-500">{error}</p>}
          {message && <p className="text-green-500">{message}</p>}
          <Button type="submit" className="bg-primary-light dark:bg-primary-dark hover:bg-primary-light dark:hover:bg-primary-dark text-white">
            Update Password
          </Button>
        </form>
      </div>

      {/* 2FA Section */}
      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-4 text-secondary-light dark:text-secondary-dark">Two-Factor Authentication (2FA)</h2>
        <div className="flex items-center space-x-4">
          <span className="text-secondary-light dark:text-secondary-dark">{is2FAEnabled ? 'Enabled' : 'Disabled'}</span>
          <Button
            onClick={toggle2FA}
            className="bg-primary-light dark:bg-primary-dark hover:bg-primary-light dark:hover:bg-primary-dark text-white"
          >
            {is2FAEnabled ? 'Disable 2FA' : 'Enable 2FA'}
          </Button>
        </div>
      </div>

      {/* Manage Subscriptions Section */}
      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-4 text-secondary-light dark:text-secondary-dark">Manage Subscription</h2>
        <Button
          onClick={() => navigate('/dashboard')}
          className="bg-primary-light dark:bg-primary-dark hover:bg-primary-light dark:hover:bg-primary-dark text-white"
        >
          View or Change Your Subscription
        </Button>
      </div>

      {/* Logout Section */}
      <div className="mb-8">
        <Button
          onClick={handleLogout}
          className="bg-red-600 hover:bg-red-500 text-white"
        >
          Logout
        </Button>
      </div>
    </div>
  );
};

export default AccountPage;
