import React, { createContext, useEffect, useState } from "react";

interface AuthContextType {
  accessToken: string | null;
  tokens_remaining: number;
  currentTier: string;
  login: (token: string, userData: any) => void;
  logout: () => void;
  updateTokensRemaining: (tokens: number) => void;
  setCurrentTier: (tier: string) => void;
  fetchAndUpdateUserData?: (token: string) => Promise<void>;
}

export const AuthContext = createContext<AuthContextType>({
  accessToken: null,
  tokens_remaining: 0,
  currentTier: 'free',
  login: () => {},
  logout: () => {},
  updateTokensRemaining: () => {},
  setCurrentTier: () => {},
  fetchAndUpdateUserData: async () => {},
});

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [accessToken, setAccessToken] = useState<string | null>(() => 
    localStorage.getItem('accessToken')
  );
  const [tokensRemaining, setTokensRemaining] = useState<number>(() => {
    const stored = localStorage.getItem('tokens_remaining');
    return stored ? parseInt(stored, 10) : 150;
  });
  const [currentTier, setCurrentTier] = useState<string>(() => {
    const stored = localStorage.getItem('subscription_tier');
    return stored ? stored : 'free';
  });

  const login = (token: string, userData: any) => {
    localStorage.setItem('accessToken', token);
    localStorage.setItem('tokens_remaining', userData.tokens_remaining.toString());
    localStorage.setItem('subscription_tier', userData.subscription_tier);
    setAccessToken(token);
    setTokensRemaining(userData.tokens_remaining);
    setCurrentTier(userData.subscription_tier);
  };

  const logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('tokens_remaining');
    localStorage.removeItem('subscription_tier');
    setAccessToken(null);
    setTokensRemaining(0);
    setCurrentTier('free');
  };

  const updateTokensRemaining = (tokens: number) => {
    localStorage.setItem('tokens_remaining', tokens.toString());
    setTokensRemaining(tokens);
  };

  const fetchAndUpdateUserData = async (token: string) => {
    try {
      const response = await fetch(process.env.REACT_APP_ENVIRONMENT_DOMAIN + '/api/user/me', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch user data');
      }

      const data = await response.json();
      if (data.tokens_remaining !== undefined) {
        updateTokensRemaining(data.tokens_remaining);
      }
      if (data.subscription_tier) {
        setCurrentTier(data.subscription_tier);
        localStorage.setItem('subscription_tier', data.subscription_tier);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      logout();
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      try {
        const decodedToken = JSON.parse(atob(token.split('.')[1]));
        if (decodedToken.exp * 1000 < Date.now()) {
          console.log('Token expired');
          logout();
        } else {
          fetchAndUpdateUserData(token);
        }
      } catch (error) {
        console.error('Error checking token:', error);
        logout();
      }
    }
  }, []);

  return (
    <AuthContext.Provider value={{
      accessToken,
      tokens_remaining: tokensRemaining,
      currentTier,
      login,
      logout,
      updateTokensRemaining,
      setCurrentTier,
      fetchAndUpdateUserData,
    }}>
      {children}
    </AuthContext.Provider>
  );
};
