import React from 'react';

interface SelectProps {
  value: string;
  onValueChange: (value: string) => void;
  placeholder?: string;
  children: React.ReactNode;
  className?: string;
}

export const SelectV2 = React.forwardRef<HTMLSelectElement, SelectProps>(
  ({ value, onValueChange, placeholder, children, className }, ref) => {
    return (
      <div className="relative">
        <select
          ref={ref}
          value={value}
          onChange={(e) => onValueChange(e.target.value)}
          className={`w-full p-2 pr-8 border border-gray-300 rounded bg-white dark:bg-gray-700 appearance-none text-gray-800 dark:text-gray-200 focus:outline-none focus:ring-2 focus:ring-primary-light dark:focus:ring-primary-dark ${className}`}
        >
          {placeholder && <option value="">{placeholder}</option>}
          {children}
        </select>
        <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
          <svg className="w-4 h-4 text-gray-400 dark:text-gray-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
          </svg>
        </div>
      </div>
    );
  }
);

SelectV2.displayName = 'SelectV2';

export const SelectTrigger = React.forwardRef<HTMLDivElement, { children: React.ReactNode; className?: string }>(
  ({ children, className }, ref) => (
    <div ref={ref} className={`relative ${className}`}>
      {children}
    </div>
  )
);

SelectTrigger.displayName = 'SelectTrigger';

export const SelectValue = ({ children, placeholder }: { children: React.ReactNode; placeholder?: string }) => (
  <>{children || placeholder}</>
);

SelectValue.displayName = 'SelectValue';

export const SelectContent = ({ children }: { children: React.ReactNode }) => <>{children}</>;
SelectContent.displayName = 'SelectContent';

export const SelectItem = React.forwardRef<HTMLOptionElement, { value: string; children: React.ReactNode }>(
  ({ value, children }, ref) => (
    <option ref={ref} value={value} className="bg-white dark:bg-gray-700 text-gray-800 dark:text-gray-200">
      {children}
    </option>
  )
);

SelectItem.displayName = 'SelectItem';
