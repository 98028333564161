import React from 'react';
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from './ui/Card';
import StripeCheckoutButton from './ui/StripeCheckoutButton';

interface SubscriptionCardProps {
  tier: 'paid' | 'premium';
  title: string;
  price: string;
  features: string[];
  tokens: number;
  currentTier?: string;
  disabled?: boolean;
}

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({
  tier,
  title,
  price,
  features,
  tokens,
  currentTier,
  disabled = false
}) => {
  return (
    <Card className="flex flex-col h-full">
      <CardHeader>
        <CardTitle className="text-2xl dark:text-white">{title}</CardTitle>
        <p className="text-3xl font-bold mb-2 text-primary-light dark:text-primary-dark">
          ${price}<span className="text-sm font-normal text-secondary-light dark:text-secondary-dark">/month</span>
        </p>
      </CardHeader>
      <CardContent>
        <ul className="space-y-2 text-gray-700 dark:text-gray-300">
          <li className="font-bold">Tokens: {tokens} monthly</li>
          {features.map((feature, index) => (
            <li key={index} className="flex items-center">
              <svg className="w-5 h-5 text-primary-light dark:text-primary-dark mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
              {feature}
            </li>
          ))}
        </ul>
      </CardContent>
      <CardFooter>
        {currentTier === tier ? (
          <button disabled className="w-full p-2 bg-gray-300 text-gray-700 rounded dark:bg-gray-600 dark:text-gray-300">
            Current Plan
          </button>
        ) : (
          <StripeCheckoutButton tier={tier} disabled={disabled} />
        )}
      </CardFooter>
    </Card>
  );
};

export default SubscriptionCard;
